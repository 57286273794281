import React from 'react'

import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <div className='not-found-page-container'>
      <div className='not-found-page'>
        <SEO title='404: Not found' />
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>The page you requested could not be found.</p>
      </div>
      <div className='col-md-12 go-to-home'>
        <Button
          href='/'
          dataElementLocation={DataElementLocations.BODY}
          dataElementLabel={'404-websitebuilder'}
          dataElementId={'404-websitebuilder-button'}
          color='cta'
          variant='contained'
          className='signup-button button-xl'
          size='large'>
            Home
        </Button>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
